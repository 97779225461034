import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import DataInsightPieChart from './DataInsightPieChart';
import DataInsightBarChart from './DataInsightBarChart';
import FilterDialog from './DataInsightFilterDialog';
import {notify} from 'react-notify-toast';
import Modal from 'react-modal';

/*
 * Houses all the logic/rendering to put together the searching for data in reference to a selected pain area
 * see https://app.asana.com/0/1200096737277303/1200355875108443/f
 */
class DataInsightIndex extends React.Component {
  static introduction = () =>
    <div className='data-insights-blurb'>
      <h2>Discovery</h2>
      <p>
                Welcome Citizen Scientist! <br/>
                Interested in what your fellow Citizen Scientists are saying about how pain affects them? <br/>
                Select an area of pain, then choose which group of people you wish to see information on.
      </p>
    </div>;

  constructor(props) {
    super(props);
    this.state = {
      pieData: null,
      barData: null,
      query: '',
      filters: {},
      diagnosisOptions: [],
      // Set up for viewing selectable insights
      selectedInsights: ['duration', 'felt_frequency_month', 'professional_impact', 'social_impact'],
      showModal: false,
      reportingUrl: `${props.protocol}://${props.reportingUrl}`
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({showModal: true});
    document.body.style.overflow = 'hidden';
  }

  handleCloseModal() {
    this.setState({showModal: false});
    document.body.style.overflow = 'unset';
  }

  // Age options for the filters
  ageOptions = {
    '18-24': '18-24',
    '25-34': '25-34',
    '35-44': '35-44',
    '45-54': '45-54',
    '55-64': '55-64',
    '65-74': '65-74',
    '75 or older': '75-200'
  };

  modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      background: 'rgba(28,28,28,.45)'
    }
  };

  /* eslint-disable camelcase */
  availableInsights = {
    professional_impact: {
      title: 'Impact on work',
      summary: 'Impact on work, housework or school activities in the last 4 weeks'
    },
    social_impact: {
      title: 'Impact on social life',
      summary: 'Impact on social and recreational activities in the last 4 weeks'
    },
    duration: {title: 'Duration of pain', summary: ''},
    felt_frequency_month: {title: 'Frequency of pain in the last 4 weeks', summary: ''}
  };

  findData = () => {
    if (this.state.query) {
      const queryParams = `query=${encodeURIComponent(this.state.query)}`;
      // use the value of the ageOptions obj for query purposes
      const filtersParams = $.param({...this.state.filters,
        ageGroup: this.ageOptions[this.state.filters.ageGroup]
      });
      fetch(`${this.state.reportingUrl}/visualization/pain-data.json?${filtersParams}&${queryParams}`, {
        method: 'GET'
      })
        .then(response => response.json())
        .then(data => {
          this.setState({
            pieData: data.pieData.array,
            barData: data.barData.array,
            diagnosisOptions: data.diagnosisOptions.array
          });
        })
        .catch(() => {
          notify.show('Something went wrong, but rest assured that we\'re working on it.', 'error');
        });
    }
  };

  handleDataSelection = e => {
    // Remove the diagnosis filter on change due to not all pain areas having the same diagnosis options
    const filters = this.state.filters;
    delete filters.diagnosis;
    this.setState({query: e.target.value, filters: filters}, this.findData);
  };

  applySearchFilters = filters => {
    this.setState({filters}, this.findData);
    this.handleCloseModal();
  };

  removeFilter = key => {
    const filters = this.state.filters;
    delete filters[key];
    this.setState({filters}, this.findData);
  };

  renderCompareTo = () =>
    <>
      <div className='grid-x grid-margin-x '>
        <div className='cell auto'>
          <span><strong>Compared To</strong></span>
        </div>
        <div className='cell shrink'>
          <button
            className='button tiny'
            onClick={this.handleOpenModal}
            type='button'>
            <i className='fa fa-plus fa-lg'/>
          </button>
        </div>
      </div>
      <hr className='margin-0 margin-bottom-1'/>
      <div className='grid-x grid-margin-x'>
        {Object.entries(this.state.filters).map(([key, value]) =>
          value ? this.renderFilterButtons(key, value) : null)}
      </div>
    </>;

  renderFilterButtons = (key, value) => <div className='cell shrink' key={key}>
    <button
      className='button data-insight-filter'
      onClick={() => this.removeFilter(key)}
      type='button'>
      {value}
      <i className='fa fa-times padding-left-1'/>
    </button>
  </div>;

  renderCharts = () =>
    <>
      <div className='grid-x grid-margin-x'>
        {this.state.selectedInsights.map(insight =>
          <div className='cell large-4 small-12 margin-bottom-3' key={insight}>
            <DataInsightPieChart
              data={this.state.pieData[insight]}
              summary={this.availableInsights[insight].summary}
              title={this.availableInsights[insight].title}
            />
          </div>)}
        {/* Always display the bar chart*/}
        <div className='cell large-4 small-12 margin-bottom-3'>
          <DataInsightBarChart data={this.state.barData.opinion_decrease} title='What makes it better'/>
        </div>
        <div className='cell large-4 small-12 margin-bottom-3'>
          <DataInsightBarChart data={this.state.barData.opinion_increase} title='What makes it worse'/>
        </div>
      </div>
    </>;

  render() {
    return (
      <>
        <div className='grid-x pain-slider-inner-container'>
          <div className='cell medium-5 large-4 small-order-1 pain-slider-cell-left grid-y grid-container'>
            {DataInsightIndex.introduction()}
          </div>
          <div className='cell medium-1 small-order-2 divider-curved'/>
          <div className='cell medium-6 large-7 small-order-3 pain-slider-cell-right'>
            <div className='data-insight-search'>
              <label>
                <select
                  className='margin-bottom-2'
                  defaultValue=''
                  onChange={this.handleDataSelection}
                >
                  <option value=''>Select an area</option>
                  {this.props.painOptions.map(entry =>
                    <option key={entry} value={entry}>{entry}</option>)}
                </select>
              </label>
            </div>
            <div className='data-insight-search'>
              {this.renderCompareTo()}
            </div>
          </div>
        </div>
        <div className='grid-x grid-container'>
          <div className='cell small-12 padding-top-2'>
            {this.state.pieData &&
                            this.renderCharts()
            }
          </div>
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick
          style={this.modalStyle}
        >
          <FilterDialog
            ageOptions={this.ageOptions}
            callback={this.applySearchFilters}
            diagnosisOptions={this.state.diagnosisOptions}
            filters={this.state.filters}
            sexOptions={this.props.sexOptions}
          />
        </Modal>
      </>
    );
  }
}

DataInsightIndex.propTypes = {
  painOptions: PropTypes.array.isRequired,
  protocol: PropTypes.string.isRequired,
  reportingUrl: PropTypes.string.isRequired,
  sexOptions: PropTypes.array.isRequired
};

export default DataInsightIndex;