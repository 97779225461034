/*
 * Filter dialog for the Data Insight queries
 * See https://app.asana.com/0/1200096737277303/1200355875108445/f
 */
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

const DialogCloseButton = () =>
  <button
    className='close-button'
    data-close=''
    type='button'
  >
    <span aria-hidden='true'><i className='fa fa-close'/> </span>
  </button>;

export default class FilterDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sex: props.filters.sex,
      ageGroup: props.filters.ageGroup,
      diagnosis: props.filters.diagnosis
    };
  }

  handleClearFilters = (callback = true) => {
    const filters = {sex: '', ageGroup: '', diagnosis: ''};

    if (callback) {
      this.setState({...filters}, () => this.props.callback({}));
    }
    else {
      this.setState({...filters});
    }
  };

  handleSubmit = e => {
    this.props.callback({...this.state});
    this.handleClearFilters(false);
    e.preventDefault();
  };

  render() {
    return (
      <div>
        <p className='lead'>Enable Search Filters</p>
        <form
          id='search-form'
          onSubmit={this.handleSubmit}
        >
          <label><b>Sex</b></label>
          <div className='grid-x grid-margin-x'>
            <div className='auto cell'>
              <label>
                <select
                  onChange={e => this.setState({sex: e.target.value})}
                  value={this.state.sex}
                >
                  <option value=''>Select an option</option>
                  {this.props.sexOptions.map(entry =>
                    <option key={entry} value={entry}>{entry}</option>)}
                </select>
              </label>
            </div>
          </div>

          <label><b>Age</b></label>
          <div className='grid-x grid-margin-x'>
            <div className='auto cell'>
              <label>
                <select
                  onChange={e => this.setState({ageGroup: e.target.value})}
                  value={this.state.ageGroup}
                >
                  <option value=''>Select an option</option>
                  {Object.keys(this.props.ageOptions).map(key =>
                    <option key={key} value={key}>{key}</option>)}
                </select>
              </label>
            </div>
          </div>

          {this.props.diagnosisOptions.length > 0 &&
                        <>
                          <label><b>Diagnosis</b></label>
                          <div className='grid-x grid-margin-x'>
                            <div className='auto cell'>
                              <label>
                                <select
                                  onChange={e => this.setState({diagnosis: e.target.value})}
                                  value={this.state.diagnosis}
                                >
                                  <option value=''>Select an option</option>
                                  {this.props.diagnosisOptions.map(entry =>
                                    <option key={entry} value={entry}>{entry}</option>)}
                                </select>
                              </label>
                            </div>
                          </div>
                        </>
          }
          <div className='grid-x grid-margin-x align-justify'>
            <div className='shrink cell'>
              <button
                className='margin-bottom-0 secondary button'
                data-close=''
                onClick={() => this.handleClearFilters(true)}
                type='button'
              >
                <i className='fa fa-times fa-fw'/>Clear Filters
              </button>
            </div>
            <div className='auto cell'>
              <button
                className='expanded margin-bottom-0 primary button'
                data-close=''
                type='submit'
              >
                <i className='fa fa-filter fa-fw'/>Apply Filters
              </button>
            </div>
          </div>
        </form>

        <DialogCloseButton/>
      </div>
    );
  }
}

FilterDialog.propTypes = {
  ageOptions: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  diagnosisOptions: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  sexOptions: PropTypes.array.isRequired
};