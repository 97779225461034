import React, {PureComponent} from 'react';
import BodyMapFront from './BodyMapFront';
import BodyMapBack from './BodyMapBack';
import MicroModal from 'micromodal';
import $ from 'jquery';
import PropTypes from 'prop-types';

const HIGHLIGHT = {OFF: '#fff', ON: '#e5d5e9', SELECTED: '#d1bce9'};
const MODAL_CONTAINER_ID = 'sidebar-form';
const WHOLE_BODY_ID = 'everywhere';

const BODY_PARTS = {
  'left abdomen': [
    'left abdomen: pain in stomach/upper abdomen',
    'left abdomen: flank pain',
    'left abdomen: pain before menstrual periods',
    'left abdomen: pain between menstrual periods'
  ],
  'right abdomen': [
    'right abdomen: pain in stomach/upper abdomen',
    'right abdomen: flank pain',
    'right abdomen: pain before menstrual periods',
    'right abdomen: pain between menstrual periods'
  ],
  'left face': [
    'left face: pain in the face',
    'left face: ear pain (earache)',
    'left face: eye pain',
    'left face: headache',
    'left face: jaw pain',
    'left face: mouth, tongue or lip symptoms',
    'left face: nose pain',
    'left face: sinus symptoms',
    'left face: sore throat',
    'left face: teeth or gum symptoms'
  ],
  'right face': [
    'right face: pain in the face',
    'right face: ear pain (earache)',
    'right face: eye pain',
    'right face: headache',
    'right face: jaw pain',
    'right face: mouth, tongue or lip symptoms',
    'right face: nose pain',
    'right face: sinus symptoms',
    'right face: sore throat',
    'right face: teeth or gum symptoms'
  ],
  'left buttocks': [
    'left buttocks: rectal or anal pain',
    'left buttocks: painful bowel movements',
    'left buttocks: tailbone pain'
  ],
  'right buttocks': [
    'right buttocks: rectal or anal pain',
    'right buttocks: painful bowel movements',
    'right buttocks: tailbone pain'
  ],
  'left groin': [
    'left groin: general groin area',
    'left groin: pain before menstrual periods',
    'left groin: pain between menstrual periods',
    'left groin: pain in genital area',
    'left groin: pain in penis',
    'left groin: painful urination',
    'left groin: pain in testicles or scrotum',
    'left groin: pain in vulva'
  ],
  'right groin': [
    'right groin: general groin area',
    'right groin: pain before menstrual periods',
    'right groin: pain between menstrual periods',
    'right groin: pain in genital area',
    'right groin: pain in penis',
    'right groin: painful urination',
    'right groin: pain in testicles or scrotum',
    'right groin: pain in vulva'
  ],
  'left chest': [
    'left chest: chest pain',
    'left chest: armpit pain',
    'left chest: flank pain',
    'left chest: heart pain',
    'left chest: nipple pain'
  ],
  'right chest': [
    'right chest: chest pain',
    'right chest: armpit pain',
    'right chest: flank pain',
    'right chest: nipple pain'
  ],
  'left hand': [
    'left hand: hand pain',
    'left hand: finger pain'
  ],
  'right hand': [
    'right hand: hand pain',
    'right hand: finger pain'
  ],
  'left upper arm': [
    'left upper arm: arm pain',
    'left upper arm: armpit pain',
    'left upper arm: flank pain'
  ],
  'right upper arm': [
    'right upper arm: arm pain',
    'right upper arm: armpit pain',
    'right upper arm: flank pain'
  ],
  'left foot': [
    'left foot: foot pain',
    'left foot: toe pain'
  ],
  'right foot': [
    'right foot: foot pain',
    'right foot: toe pain'
  ]
};

const capitalizeFirstLetter = str => str[0].toUpperCase() + str.slice(1);

export default class BodyMap extends PureComponent {
  state = {
    frontSideActive: true,
    selectedBodyParts: {},
    isWholeBodySelected: false,
    activeBodyPart: null
  };

  componentDidMount() {
    MicroModal.init();
    this.setInitialValues();
  }

  componentDidUpdate() {
    if (BODY_PARTS[this.state.activeBodyPart]) {
      this.openModalDialog();
    }
  }

  setInitialValues = () => {
    const {values} = this.props;

    if (values.length === 1 && values[0].name === WHOLE_BODY_ID) {
      this.toggleWholeBodySelection();
    }
    else {
      const newParts = {};

      for (const value of values) {
        const bodyPart = value.name.split(': ').shift();
        const elem = document.getElementById(bodyPart);

        if (elem) {
          elem.setAttribute('fill', HIGHLIGHT.ON);
          newParts[bodyPart] = value.name;
        }
      }

      this.setState({selectedBodyParts: newParts});
    }
  };

  handleBodySideChange = () => {
    this.setState({frontSideActive: !this.state.frontSideActive});
  };

  openModalDialog = () => {
    MicroModal.show(MODAL_CONTAINER_ID);
    $('.modal-overlay').click(e => {
      if ($(e.target).closest('.modal-container').length === 0) {
        MicroModal.close(MODAL_CONTAINER_ID);
        this.unsetActiveBodyPart();
      }
    });
  };

  unsetActiveBodyPart = () => {
    const {selectedBodyParts, activeBodyPart} = this.state;

    if (activeBodyPart) {
      if (selectedBodyParts[activeBodyPart]) {
        document.getElementById(activeBodyPart).setAttribute('fill', HIGHLIGHT.ON);
      }
      else {
        document.getElementById(activeBodyPart).setAttribute('fill', HIGHLIGHT.OFF);
      }
    }

    this.setState({activeBodyPart: null});
  };

  /* eslint-disable max-statements */
  toggleBodyPart = e => {
    const {selectedBodyParts} = this.state;
    let {activeBodyPart} = this.state;

    if (e.target.id) {
      const bodyPart = e.target.id;

      // Clicked on a body part that is not in the list of selected body parts
      if (!selectedBodyParts[bodyPart]) {
        // if another body part is active, make it inactive
        if (activeBodyPart) {
          document.getElementById(activeBodyPart).setAttribute('fill', HIGHLIGHT.ON);
        }

        // make the new body part active and add it to the list
        document.getElementById(bodyPart).setAttribute('fill', HIGHLIGHT.SELECTED);

        if (BODY_PARTS[bodyPart]) {
          // to indicate that a sub-option is not yet selected
          selectedBodyParts[bodyPart] = false;
        }
        else {
          selectedBodyParts[bodyPart] = bodyPart;
        }

        activeBodyPart = bodyPart;
      }
      // clicked on an active body part, need to inactivate it and remove it from the list
      else if (activeBodyPart === bodyPart) {
        document.getElementById(activeBodyPart).setAttribute('fill', HIGHLIGHT.OFF);
        delete selectedBodyParts[bodyPart];
        activeBodyPart = null;
      }
      // clicked on a body part with a dropdown, activating this body part
      else if (BODY_PARTS[bodyPart]) {
        if (activeBodyPart) {
          document.getElementById(activeBodyPart).setAttribute('fill', HIGHLIGHT.ON);
        }

        document.getElementById(bodyPart).setAttribute('fill', HIGHLIGHT.SELECTED);
        activeBodyPart = bodyPart;
      }
      // clicked on a body part that doesn't have a dropdown, remove it from the list
      else {
        if (activeBodyPart) {
          document.getElementById(activeBodyPart).setAttribute('fill', HIGHLIGHT.ON);
        }

        document.getElementById(bodyPart).setAttribute('fill', HIGHLIGHT.OFF);
        delete selectedBodyParts[bodyPart];
        activeBodyPart = null;
      }

      const isWholeBodySelected = selectedBodyParts.length === this.props.suggestions.length - 1;

      this.setState({selectedBodyParts: {...selectedBodyParts}, isWholeBodySelected, activeBodyPart});
    }
    else if (e.target.tagName.toUpperCase() === 'SELECT') {
      selectedBodyParts[activeBodyPart] = e.target.options[e.target.selectedIndex].id;

      // clicked on a sub-option for a specific body part
      if (selectedBodyParts[activeBodyPart]) {
        document.getElementById(activeBodyPart).setAttribute('fill', HIGHLIGHT.ON);
      }
      // clicked on a sub-option without id (i.e. removing body part)
      else {
        delete selectedBodyParts[activeBodyPart];
        document.getElementById(activeBodyPart).setAttribute('fill', HIGHLIGHT.OFF);
      }

      this.setState({selectedBodyParts: {...selectedBodyParts}, activeBodyPart: null});
    }
    // clicked on "remove body part" from the dropdown
    else if (BODY_PARTS[activeBodyPart]) {
      delete selectedBodyParts[activeBodyPart];

      if (activeBodyPart) {
        document.getElementById(activeBodyPart).setAttribute('fill', HIGHLIGHT.OFF);
      }

      this.setState({selectedBodyParts: {...selectedBodyParts}, activeBodyPart: null});
    }
  };
    /* eslint-enable max-statements */

  toggleWholeBodySelection = () => {
    const {isWholeBodySelected} = this.state;

    if (isWholeBodySelected) {
      $('svg [id]').attr('fill', HIGHLIGHT.OFF);
      this.setState({isWholeBodySelected: false, selectedBodyParts: {}});
    }
    else {
      $('svg [id]').attr('fill', HIGHLIGHT.ON);
      const newParts = this.props.suggestions
        .filter(suggestion => suggestion.name !== WHOLE_BODY_ID)
        .map(suggestion => suggestion.name);
      this.setState({isWholeBodySelected: true, selectedBodyParts: newParts, activeBodyPart: null});
    }
  };

  renderSidebar = () => {
    const {isWholeBodySelected, activeBodyPart} = this.state;

    return (
      <div>
        <h2>{this.props.header}</h2>
        <p dangerouslySetInnerHTML={{__html: this.props.description}}/>
        <p>
          <b>On the body map</b> select the region where the pain originates or is the most intense.
        </p>
        <label className='checkbox margin-bottom-1'>
          <input checked={isWholeBodySelected} onChange={this.toggleWholeBodySelection} type='checkbox'/>
          <span/>
                    It&apos;s painful everywhere
        </label>
        {activeBodyPart && <div>
          <div>{BODY_PARTS[activeBodyPart] && this.renderModalSidebarForm()}</div>
          <div className='show-for-medium'>
            <p className=''><b>You have selected:</b></p>
            <b className='h2'>{activeBodyPart}</b>
          </div>
        </div>}
      </div>
    );
  };

  renderSidebarForm = () => {
    const {activeBodyPart, selectedBodyParts} = this.state;
    return activeBodyPart && <div>
      <h6>Can we get some more details?</h6>
      <p>You said the pain originates in your <b>{activeBodyPart}</b>. Is it more specific than that?</p>
      <select onChange={this.toggleBodyPart} value={selectedBodyParts[activeBodyPart]}>
        <option>Select one of the following</option>
        {BODY_PARTS[activeBodyPart].map(bodyPart =>
          <option id={bodyPart} key={bodyPart} value={bodyPart}>
            {capitalizeFirstLetter(bodyPart.split(': ').pop())}
          </option>)}
      </select>

      <a className='clear expanded margin-0 button' onClick={this.toggleBodyPart}>Remove body part</a>
    </div>;
  };

  renderModalSidebarForm = () => <div aria-hidden='true' className='modal micromodal-slide' id={MODAL_CONTAINER_ID}>
    <div className='modal-overlay'>
      <div data-micromodal-close tabIndex='-1'>
        <div aria-modal='true' className='modal-container' role='dialog'>
          <header className='modal-header'>
            <button className='modal-close' data-custom-close={MODAL_CONTAINER_ID} data-micromodal-close
              onClick={this.unsetActiveBodyPart} type='button'/>
          </header>
          <section className='modal-content'>{this.renderSidebarForm()}</section>
        </div>
      </div>
    </div>
  </div>;

  renderBodyMap = () => {
    const {frontSideActive} = this.state;
    return (
      <div>
        <div className='small button-group align-center'>
          <a className={`${frontSideActive ? '' : 'hollow'} button`}
            onClick={frontSideActive ? null : this.handleBodySideChange}>Front</a>
          <a className={`${frontSideActive ? 'hollow' : ''} button`}
            onClick={frontSideActive ? this.handleBodySideChange : null}>Back</a>
        </div>
        <div className='grid-x align-center'>
          <div className='small-1 medium-2 cell'>
            <div className='margin-top-3 text-right'>
              <b>{frontSideActive ? 'Right' : 'Left'}</b>
            </div>
          </div>
          <div className='auto cell' onClick={this.toggleBodyPart}>
            <div className={frontSideActive ? '' : 'hide'}><BodyMapFront/></div>
            <div className={frontSideActive ? 'hide' : ''}><BodyMapBack/></div>
          </div>
          <div className='small-1 medium-2 cell'>
            <div className='margin-top-3 text-left'>
              <b>{frontSideActive ? 'Left' : 'Right'}</b>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderFormValues = () => {
    const {attribute} = this.props;
    const {isWholeBodySelected, selectedBodyParts} = this.state;

    if (isWholeBodySelected) {
      return <input name={attribute} type='hidden' value='everywhere'/>;
    }

    const inputs = [];

    for (const bodyPart in selectedBodyParts) {
      if (bodyPart && selectedBodyParts[bodyPart]) {
        inputs.push(<input key={bodyPart} name={attribute} type='hidden' value={selectedBodyParts[bodyPart]}/>);
      }
    }

    return inputs;
  };

  render() {
    return (
      <div className='pain-slider-main-container grid-container'>
        <div className='grid-x pain-slider-inner-container'>
          <div className='cell medium-5 large-4 small-order-1 pain-slider-cell-left grid-y'>
            {this.renderSidebar()}
          </div>
          <div className='cell medium-1 small-order-2 divider-curved'/>
          <div className='cell auto small-order-3 pain-slider-cell-right grid-y'>
            {this.renderBodyMap()}
          </div>
        </div>
        {this.renderFormValues()}
      </div>
    );
  }
}

BodyMap.defaultProps = {
  values: []
};

BodyMap.propTypes = {
  attribute: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string, id: PropTypes.string})).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string, id: PropTypes.string}))
};