import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {ReactTags} from 'react-tag-autocomplete';

const TagInput = ({tagsCallback, tags, suggestions}) => {
  const onAdd = useCallback(newTag => {
    if (tagsCallback) {
      tagsCallback([].concat(tags, newTag));
    }
  },
  [tags]);

  const onDelete = useCallback(tagIndex => {
    if (tagsCallback) {
      tagsCallback([].concat(tags.filter((_, i) => i !== tagIndex)));
    }
  },
  [tags]);

  return (
    <ReactTags
      allowNew
      labelText={null}
      onAdd={onAdd}
      onDelete={onDelete}
      selected={tags}
      suggestions={suggestions}
    />
  );
};

TagInput.defaultProps = {
  tags: [],
  tagsCallback: null
};

TagInput.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})),
  tagsCallback: PropTypes.func
};

export default TagInput;