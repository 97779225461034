import React from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import PropTypes from 'prop-types';

const SurveysBreakdownGraph = props =>
  <ResponsiveContainer height={250} width='100%'>
    <BarChart data={props.data}>
      <CartesianGrid stroke='#eeeeee'/>
      <XAxis dataKey='date' tick={{fontSize: 12}}/>
      <YAxis tick={{fontSize: 12}}/>
      <Tooltip/>
      <Legend/>
      <Bar dataKey='complete' fill='#1976d2' stackId='a'/>
      <Bar dataKey='incomplete' fill='#00a65a' stackId='a'/>
    </BarChart>
  </ResponsiveContainer>;

SurveysBreakdownGraph.propTypes = {
  data: PropTypes.array.isRequired
};

export default SurveysBreakdownGraph;