import React, {useState, useEffect} from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label
} from 'recharts';
import PropTypes from 'prop-types';

const DataInsightBarChart = ({data, totalCount, filtersCount, title}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 425);

  const updateMedia = () => {
    setIsSmallScreen(window.innerWidth < 425);
  };

  const CustomizedTick = props => {
    const {x, y, payload} = props;
    const str = payload.value.split('');
    // line length will be 12 characters or less
    const lineLength = 12;
    let index = lineLength;
    let lines = [];

    while (index < str.length && index >= 0) {
      if (str[index] === ' ') {
        str[index] = '#';
        index += lineLength;
      }

      index--;
    }

    lines = str.join('').split('#');

    return (
      <g transform={`translate(${x},${y})`}>
        <text dy={16} fill='#666' fontSize={isSmallScreen ? 8 : 12} fontWeight='bold' x={0} y={0}>
          <tspan textAnchor='middle' x='0'>
            {lines[0]}
          </tspan>
          <tspan dy={isSmallScreen ? '10' : '16'} textAnchor='middle' x='0'>
            {lines[1]}
          </tspan>
          <tspan dy={isSmallScreen ? '10' : '16'} textAnchor='middle' x='0'>
            {lines[2]}
          </tspan>
        </text>
      </g>
    );
  };

  CustomizedTick.propTypes = {
    payload: PropTypes.object,
    x: PropTypes.number,
    y: PropTypes.number
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);

    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <>
      <div className='data-insight-bar-chart-container' >
        <p><strong>{title}</strong></p>
      </div>
      <div className='data-insight-bar-chart-container' >
        <BarChart
          barCategoryGap='10%'
          data={data}
          height={isSmallScreen ? 300 : 400}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
          }}
          width={isSmallScreen ? 250 : 400}
        >
          <XAxis dataKey='name' height={isSmallScreen ? 50 : 75} interval={0} tick={<CustomizedTick/>}/>
          <YAxis
            // hide y-axis ticks
            tickFormatter={() => ''}
            tickLine={false}
            width={25}
          >
            <Label
              angle={270}
              style={{
                textAnchor: 'middle',
                fontSize: isSmallScreen ? 10 : 14
              }}
              value='Everyone'
            />
          </YAxis>
          <Tooltip/>
          <Legend/>
          <Bar dataKey='value' fill='#399bbe' name={` Everyone (${totalCount})`} unit='%'/>
          {/* eslint-disable eqeqeq, no-eq-null */}
          {data[0].filtersValue != null &&
                    <Bar dataKey='filtersValue' fill='#ff9e59' name={` My filters (${filtersCount})`} unit='%'/>}
          {/* eslint-enable eqeqeq, no-eq-null */}

        </BarChart>
      </div>
      <div className='data-insight-updated-container' >
        <p><i>Source data is updated approximately every 24 hours.</i></p>
      </div>
    </>
  );
};

DataInsightBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  filtersCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired
};

export default DataInsightBarChart;