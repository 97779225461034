import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import {
  severityLevels,
  employmentImpactLevels
} from '../../assets/images/pain_severity_elements/pain_expression_icons/pain_expression_icons';

const PainSlider = props => {
  const {titleText, descriptionText, fieldName, initialValue, sliderFor} = props;
  const [painLevel, setPainLevel] = useState(initialValue);
  useEffect(() => {
    const slider = $('.slider');
    $('.pain-slider-main-container').foundation();
    // We only need to initialize the items inside this react component
    slider.on('moved.zf.slider', () => {
      const sliderValue = parseInt($('.slider-handle').attr('aria-valuenow'), 10);
      setPainLevel(sliderValue);
    });
  });

  const PAIN_LEVELS = sliderFor === 'employed_impact'
    ? employmentImpactLevels
    : severityLevels;

  const painIconIndicesToBeHidden = [1, 3, 5, 7, 9];
  /*
   * Declare icons(by entering their zero based index from 'PainSlider.defaultProps') for
   * showing in small screen here.
   */

  const iconForPainSliderPosition = Math.floor(painLevel / 11 * props.painIconReferenceArray.length);
  /*
   * Use this when 'painLevel' starts at 1 and finishes at 10 or 100. This takes care of the situation where
   * iconForPainSliderPosition might be set to a number greater than iconForPainSliderPosition array length.
   * const iconForPainSliderPosition =
   *  Math.min(Math.floor(painLevel / 100 * painIconReferenceArray.length), painIconReferenceArray.length - 1);
   * className='icon-reflect-styles'}
   * Example1:
   *  when painIconReferenceArray.length = 11, painLevel = 11, divide by = 11, starting values = 0 (without Math.min)
   * Example2:
   *  when painIconReferenceArray.length = 11, painLevel = 11, divide by = 11, starting values = 1
   */

  const generateImageSource = imageRenderingPosition => {
    let url = '';
    const urlForPainSliderPosition = PAIN_LEVELS[`PAIN_LEVEL_${iconForPainSliderPosition}`].url;
    const urlForImageRenderingIndex = PAIN_LEVELS[`PAIN_LEVEL_${imageRenderingPosition}`].url;

    if (
      (imageRenderingPosition === 0 || imageRenderingPosition === 10) &&
                iconForPainSliderPosition === imageRenderingPosition ||
            imageRenderingPosition !== 0 && imageRenderingPosition !== 10 &&
            (urlForPainSliderPosition === urlForImageRenderingIndex ||
                iconForPainSliderPosition === imageRenderingPosition)
    ) {
      url = PAIN_LEVELS[`PAIN_LEVEL_${imageRenderingPosition}`].url;
    }
    else {
      url = PAIN_LEVELS[`PAIN_LEVEL_${imageRenderingPosition}`].greyUrl;
    }

    return url;
  };

  const levelTextClasses = [''];

  if (iconForPainSliderPosition > 0 && iconForPainSliderPosition < 10) {
    levelTextClasses.push('hidden');
  }

  return (
    <div className='pain-slider-main-container grid-container'>
      <div className='grid-x pain-slider-inner-container'>
        <div className='cell medium-5 large-4 small-order-1 pain-slider-cell-left grid-container'>
          <div className='cell'>
            <h2>{titleText}</h2>
            <p className='pain-severity-description' dangerouslySetInnerHTML={{__html: descriptionText}}/>
          </div>
          <div className='cell'>
            <div className='text-center'>
              <img
                className='icon-reflect-styles'
                src={PAIN_LEVELS[`PAIN_LEVEL_${iconForPainSliderPosition}`].url}
              />
              <h3 className='selected-pain-level-text'>
                <span className={levelTextClasses.join(' ')}>
                  {PAIN_LEVELS[`PAIN_LEVEL_${iconForPainSliderPosition}`].name}
                </span>&nbsp;
              </h3>
            </div>
          </div>
        </div>
        <div className='cell medium-1 small-order-2 divider-curved'/>
        <div className='cell medium-6 large-7 small-order-3 pain-slider-cell-right grid-y grid-container'>
          <div className='cell medium-2 show-for-medium'/>
          <div className='cell small-4 medium-2 pain-slider-container'>
            <div
              className='slider slider-styles'
              data-end={10}
              data-initial-start={painLevel}
              data-slider
              data-step='1'
            >
              <span
                className='slider-handle rounded slider-handle-styles'
                data-slider-handle
                role='slider'
                tabIndex='1'
              />
              <span className='slider-fill slider-fill-styles' data-slider-fill/>
              <input
                name={`pain_${fieldName}[${fieldName}]`}
                type='hidden'
              />
            </div>
          </div>
          <div className='pain-level-numeric-values align-justify margin-bottom-1'>
            {/* eslint-disable-next-line react/no-array-index-key */}
            {props.painIconReferenceArray.map((element, index) => <h3 className='' key={index}>
              {element}
            </h3>)}
          </div>
          <div>
            <div className='pain-level-icons align-justify margin-bottom-1'>
              {props.painIconReferenceArray.map((element, index) => {
                const alt = PAIN_LEVELS[`PAIN_LEVEL_${index}`].name;

                if (!painIconIndicesToBeHidden.includes(index)) {
                  return (
                    <img
                      alt={alt}
                      className='column pain-icon-size'
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      src={generateImageSource(index)}
                    />
                  );
                }

                return null;
              })}
            </div>
          </div>
          <div className='cell auto'/>
        </div>
      </div>
    </div>
  );
};

PainSlider.defaultProps = {
  descriptionText: '',
  fieldName: 'severity',
  initialValue: 0,
  painIconReferenceArray: Array.of(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10),
  titleText: '',
  sliderFor: 'severity'
};

PainSlider.propTypes = {
  descriptionText: PropTypes.string,
  fieldName: PropTypes.string,
  initialValue: PropTypes.number,
  painIconReferenceArray: PropTypes.array,
  sliderFor: PropTypes.string,
  titleText: PropTypes.string
};

export default PainSlider;