/*
 * Dialog for social media sharing options for the site
 * See https://app.asana.com/0/1200096737277303/1200460341247915/f
 */
import React, {PureComponent} from 'react';
import Foundation from 'foundation-sites';
import $ from 'jquery';
import {notify} from 'react-notify-toast';

export default class ShareDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {siteURL: window.location.origin};
    this.dialog = null;
  }

  handleOpenSearchDialog = () => {
    if (!this.dialog) {
      this.dialog = new Foundation.Reveal($('#share-dialog'));
    }

    this.dialog.open();
  };

  handleCopyText = () => {
    navigator.clipboard.writeText(this.state.siteURL);
    notify.show('Site link copied', 'success');
  };

  render() {
    return (
      <>
        <p>
          <button className='button padding-horizontal-3'
            data-open='share-dialog'
            onClick={this.handleOpenSearchDialog}
            type='button'
          >
                        Share the site
          </button>
        </p>
        <div
          className='reveal share-dialog padding-2 sans-serif' id='share-dialog'>
          <h2>Share the site</h2>
          <p className=''>Calling all Citizen Scientists!</p>
          <p>
                        Join us to uncover the burden of pain. Tell people you know about Citizen Science!
                        Sharing experiences with pain can help us understand it better and find a way forward, together.
          </p>
          <p className='margin-bottom-2'>
                        You can copy the link to the site and share it with people
                        or on your favorite social media sites.
          </p>
          <div className='input-group share-link'>
            <input className='input-group-field'
              defaultValue={this.state.siteURL}
              readOnly
              type='text'
            />
            <input className='button input-group-button'
              onClick={this.handleCopyText}
              type='button'
              value='Copy Site Link'
            />
          </div>

          <div>

            <button
              className='margin-bottom-0 primary button padding-horizontal-3 float-right'
              data-close=''
              type='button'
            >
                            Close
            </button>
          </div>
          <button aria-label='Close reveal' className='close-button' data-close='' type='button'>
            <span aria-hidden='true' className=''>&times;</span>
          </button>
        </div>
      </>
    );
  }
}