import {Controller} from '@hotwired/stimulus';
import MicroModal from 'micromodal';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['termsCheckBox', 'startSurveyButton'];

  agree = () => { // eslint-disable-line class-methods-use-this
    document.getElementById('user_terms_of_service').checked = true;
    MicroModal.close(document.querySelector('.modal').id);
  };

  decline = () => { // eslint-disable-line class-methods-use-this
    document.getElementById('user_terms_of_service').checked = false;
    MicroModal.close(document.querySelector('.modal').id);
  };

  toggleStartSurveyButtonState = () => {
    const termsCheckBox = $(this.termsCheckBoxTarget);
    const startSurveyButton = $(this.startSurveyButtonTarget);

    if (termsCheckBox.prop('checked')) {
      startSurveyButton.prop('disabled', false);
    }
    else {
      startSurveyButton.prop('disabled', true);
    }
  };
}