import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['day', 'week', 'month'];

  connect() {
    const selected = this.element.getAttribute('data-selected');
    this.showOnly(selected);
  }

  showOnly(selected) {
    $(this.dayTarget).addClass('hidden').prop('disabled', true);
    $(this.weekTarget).addClass('hidden').prop('disabled', true);
    $(this.monthTarget).addClass('hidden').prop('disabled', true);

    if (selected) {
      if (selected === 'Daily') {
        $(this.dayTarget).removeClass('hidden').find('input').prop('disabled', false);
      }
      else if (selected === 'Weekly') {
        $(this.weekTarget).removeClass('hidden').find('input').prop('disabled', false);
      }
      else if (selected === 'Monthly') {
        $(this.monthTarget).removeClass('hidden').find('input').prop('disabled', false);
      }
    }
  }

  schedule(e) {
    const selected = e.target.options[e.target.selectedIndex].innerHTML;
    this.showOnly(selected);
  }
}