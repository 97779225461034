import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import DataInsightBarChart from './DataInsightBarChart';
import FilterDialog from './DataInsightFilterDialog';
import {notify} from 'react-notify-toast';
import Modal from 'react-modal';

class DataInsightIndex extends React.Component {
  static introduction = () =>
    <div className='data-insights-blurb'>
      <h2>Your experience<br/>with COVID</h2>
      <p>
                See how your experiences with long COVID compare with others.
      </p>
    </div>;

  constructor(props) {
    super(props);
    this.state = {
      barData: null,
      totalCount: 0,
      filtersCount: 0,
      query: '',
      filters: {},
      showModal: false,
      reportingUrl: `${props.protocol}://${props.reportingUrl}`
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({showModal: true});
    document.body.style.overflow = 'hidden';
  }

  handleCloseModal() {
    this.setState({showModal: false});
    document.body.style.overflow = 'unset';
  }

  // Age options for the filters
  ageOptions = {
    '18-24': '18-24',
    '25-34': '25-34',
    '35-44': '35-44',
    '45-54': '45-54',
    '55-64': '55-64',
    '65-74': '65-74',
    '75 or older': '75-200'
  };

  modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      background: 'rgba(28,28,28,.45)'
    }
  };

  /* eslint-disable no-eq-null, eqeqeq */

  findData = () => {
    if (this.state.query) {
      const queryParams = `query=${encodeURIComponent(this.state.query)}`;
      // use the value of the ageOptions obj for query purposes
      const filtersParams = $.param({...this.state.filters,
        ageGroup: this.ageOptions[this.state.filters.ageGroup]
      });
      fetch(`${this.state.reportingUrl}/visualization/long-covid-data.json?${filtersParams}&${queryParams}`, {
        method: 'GET'
      })
        .then(response => response.json())
        .then(data => {
          // deep copy objects
          const allBarData = JSON.parse(JSON.stringify(data.covidBarData));
          const filtersBarData = JSON.parse(JSON.stringify(data.covidFiltersBarData));
          const totalCount = allBarData.array.data_points_count;
          let filtersCount = 0;

          if (filtersBarData.array != null) {
            for (let i = 0; i < allBarData.array.data.length; i++) {
              allBarData.array.data[i].filtersValue = filtersBarData.array.data[i].value;
            }

            filtersCount = filtersBarData.array.data_points_count;
          }

          this.setState({barData: allBarData.array.data, totalCount: totalCount, filtersCount: filtersCount});
        })
        .catch(() => {
          notify.show('Something went wrong, but rest assured that we\'re working on it.', 'error');
        });
    }
  };

  /* eslint-enable no-eq-null, eqeqeq */

  handleDataSelection = e => {
    const filters = this.state.filters;
    this.setState({query: e.target.value, filters: filters}, this.findData);
  };

  applySearchFilters = filters => {
    this.setState({filters}, this.findData);
    this.handleCloseModal();
  };

  removeFilter = key => {
    const filters = this.state.filters;
    delete filters[key];
    this.setState({filters}, this.findData);
  };

  renderCompareTo = () =>
    <>
      <div className='grid-x grid-margin-x '>
        <div className='cell auto'>
          <span><strong>Compared To</strong></span>
        </div>
        <div className='cell shrink'>
          <button
            className='button tiny'
            onClick={this.handleOpenModal}
            type='button'>
            <i className='fa fa-plus fa-lg'/>
          </button>
        </div>
      </div>
      <hr className='margin-0 margin-bottom-1'/>
      <div className='grid-x grid-margin-x'>
        {Object.entries(this.state.filters).map(([key, value]) =>
          value ? this.renderFilterButtons(key, value) : null)}
      </div>
    </>;

  renderFilterButtons = (key, value) => <div className='cell shrink' key={key}>
    <button
      className='button data-insight-filter'
      onClick={() => this.removeFilter(key)}
      type='button'>
      {value}
      <i className='fa fa-times padding-left-1'/>
    </button>
  </div>;

  renderBarGraphs = () =>
    <>
      <div style={{maxWidth: 400, margin: '40px auto 0'}} >
        <DataInsightBarChart
          data={this.state.barData}
          filtersCount={this.state.filtersCount}
          title={this.state.query}
          totalCount={this.state.totalCount}
        />
      </div>
    </>;

  render() {
    return (
      <>
        <div className='grid-x pain-slider-inner-container'>
          <div className='cell medium-5 large-4 small-order-1 pain-slider-cell-left grid-y grid-container'>
            {DataInsightIndex.introduction()}
          </div>
          <div className='cell medium-1 small-order-2 divider-curved'/>
          <div className='cell medium-6 large-7 small-order-3 pain-slider-cell-right'>
            <div className='data-insight-search'>
              <label>
                <select
                  className='margin-bottom-2'
                  defaultValue=''
                  onChange={this.handleDataSelection}
                >
                  <option value=''>Select a symptom</option>
                  {this.props.symptomsOptions.map(entry =>
                    <option key={entry} value={entry}>{entry}</option>)}
                </select>
              </label>
            </div>
            <div className='data-insight-search'>
              {this.renderCompareTo()}
            </div>
          </div>
        </div>

        {this.state.barData &&
                    this.renderBarGraphs()
        }
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick
          style={this.modalStyle}
        >
          <FilterDialog
            ageOptions={this.ageOptions}
            callback={this.applySearchFilters}
            ethnicityOptions={this.props.ethnicityOptions}
            filters={this.state.filters}
            provinceOptions={this.props.provinceOptions}
            sexOptions={this.props.sexOptions}
          />
        </Modal>

      </>
    );
  }
}

DataInsightIndex.propTypes = {
  ethnicityOptions: PropTypes.array.isRequired,
  protocol: PropTypes.string.isRequired,
  provinceOptions: PropTypes.array.isRequired,
  reportingUrl: PropTypes.string.isRequired,
  sexOptions: PropTypes.array.isRequired,
  symptomsOptions: PropTypes.array.isRequired
};

export default DataInsightIndex;