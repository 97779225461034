/*
 * Pie Charts for the Data Insights page
 * See https://app.asana.com/0/1200096737277303/1200360493777648/f
 */

import React from 'react';
import PropTypes from 'prop-types';
import {PieChart, Pie, Cell, ResponsiveContainer, Tooltip} from 'recharts';

const COLORS = [
  '#D199E5',
  '#bddadf',
  '#d3c9d6',
  '#A0D5DE',
  '#F1F8FA',
  '#44ACBD'
];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text dominantBaseline='central'
      fill='black'
      fontSize='20px'
      textAnchor={x > cx ? 'start' : 'end'}
      x={x}
      y={y}>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const DataInsightPieChart = ({data, title, summary}) =>
  <>
    <p className={summary && 'data-insight-tooltip'}>
      <strong>{title}</strong>
      <span className='data-insight-tooltip-text'>{summary}</span>
    </p>
    {data.length > 0 &&
            <div className='data-insight-chart'>
              <ResponsiveContainer height={275} width='99%'>
                <PieChart>
                  <Pie
                    cx='50%'
                    cy='50%'
                    data={data}
                    dataKey='value'
                    fill='#8884d8'
                    label={renderCustomizedLabel}
                    labelLine={false}
                    outerRadius={125}
                  >
                    {data.map((entry, index) =>
                    // eslint-disable-next-line react/no-array-index-key
                      <Cell fill={COLORS[index % COLORS.length]} key={`cell-${index}`}/>)}
                  </Pie>
                  <Tooltip/>
                </PieChart>
              </ResponsiveContainer>
            </div>
    }
    {data.length === 0 &&
            <p>No data available</p>
    }
  </>;

DataInsightPieChart.propTypes = {
  data: PropTypes.array.isRequired,
  summary: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default DataInsightPieChart;